<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Stagiaires
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiAccountMultiple }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Rechercher"
          single-line
          hide-details
          @change="searchStud"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="$router.push({name:'student-new'})"
        >
          Nouveau stagiaire
        </v-btn>
      </v-card-text>
      <v-divider>
      </v-divider>

      <v-data-table
        :calculate-widths="true"
        :disable-pagination="true"
        :header-props="{ sortByText: 'Trier par' }"
        :loading="loading"
        loading-text="Récupération des apprenants..."
        no-data-text="Saisir au moins trois caractères"
        no-results-text="Pas de résultats trouvé :("
        :headers="headers"
        :items="students"
        item-key="id"
        class="table-rounded"
        @click:row="goToStud"
      >
        <!-- DATE DE CONTACT -->
        <template #[`item.lastContactDate`]="{item}">
          <div
            v-if="item.contacts.length > 0"
            class="d-flex flex-column"
          >
            {{ item.lastContactDate | date('dd/MM/yyyy à HH:mm') }}
          </div>
          <div
            v-else
            class="d-flex flex-column"
          >
            Pas encore contacté
          </div>
        </template>

        <!-- STATUS -->
        <template #[`item.status`]="{item}">
          <div class="d-flex flex-column text-center">
            <v-chip
              class="justify-center font-weight-medium text-center"
              :color="formationStatus[item.status].color"
              small
            >
              {{ formationStatus[item.status].text }}
            </v-chip>
          </div>
        </template>
        <!-- DATE DE RAPPEL -->
        <template #[`item.rappel`]="{item}">
          <div class="d-flex flex-column">
            {{ new Date(item.rappel) | date('dd/MM/yyyy à HH:mm') }}
          </div>
        </template>
        <!-- FORMATION -->
        <template
          #[`item.formation`]="{item}"
        >
          <div
            v-if="item.formation"
            class="d-flex flex-column text-truncate"
          >
            {{ item.formation.nom.substring(0, 16) }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiMagnify, mdiAccountMultiple,
} from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      chipColor: 'warning',
      students: [],
      search: '',
      items: {},
      noresults: true,
      headers: [
        { text: 'Nom', value: 'nom' },
        { text: 'Prenom', value: 'prenom' },
        { text: 'Formation', value: 'formation' },
        { text: 'Rappel', value: 'rappel' },
        { text: 'Status', value: 'status' },
      ],
      statusColor: {
        rappel: 'primary',
        Professional: 'success',
        dead: 'error',
        nrp: 'warning',
        Applied: 'info',
      },
      formationStatus: {
        enFormation: {
          color: 'primary',
          text: 'En Formation',
        },
        avantFormation: {
          color: 'info',
          text: 'Entré en formation',
        },
        inscritCertif: {
          color: 'info',
          text: 'Inscrit Certif',
        },
        certifPassee: {
          color: 'success',
          text: 'Certif Passée',
        },
        sortieFormation: {
          color: 'warning',
          text: 'Sorti de formation',
        },
        autre: {
          color: 'alert',
          text: 'Autre',
        },

      },
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMagnify,
        mdiAccountMultiple,
      },

    }
  },
  computed: {
    loading() {
      return this.search.length > 2 && this.students.length === 0 && !this.noresults
    },
  },
  watch: {
    search(e) {
      this.searchStud(e)
    },
  },

  created() {
  },
  methods: {
    searchStud(e) {
      if (e.length > 2) {
        this.stud = []
        this.$http.get(`${process.env.VUE_APP_API_URL}/students?_q=${e}`)
          .then(response => {
            this.noresults = response.data.length === 0
            this.students = response.data
          },
          error => {
            console.log(error)
          })
      }
    },
    goToStud(e) {
      this.$router.push({ name: 'student-single', params: { id: e.id } })
    },
  },

}
</script>
