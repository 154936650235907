var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v(" Stagiaires ")]),_c('v-icon',{attrs:{"top":"","right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMultiple)+" ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":""},on:{"change":_vm.searchStud},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"me-3 mt-4 ml-10",attrs:{"color":"primary","right":"","top":""},on:{"click":function($event){return _vm.$router.push({name:'student-new'})}}},[_vm._v(" Nouveau stagiaire ")])],1),_c('v-divider'),_c('v-data-table',{staticClass:"table-rounded",attrs:{"calculate-widths":true,"disable-pagination":true,"header-props":{ sortByText: 'Trier par' },"loading":_vm.loading,"loading-text":"Récupération des apprenants...","no-data-text":"Saisir au moins trois caractères","no-results-text":"Pas de résultats trouvé :(","headers":_vm.headers,"items":_vm.students,"item-key":"id"},on:{"click:row":_vm.goToStud},scopedSlots:_vm._u([{key:"item.lastContactDate",fn:function(ref){
var item = ref.item;
return [(item.contacts.length > 0)?_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("date")(item.lastContactDate,'dd/MM/yyyy à HH:mm'))+" ")]):_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Pas encore contacté ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column text-center"},[_c('v-chip',{staticClass:"justify-center font-weight-medium text-center",attrs:{"color":_vm.formationStatus[item.status].color,"small":""}},[_vm._v(" "+_vm._s(_vm.formationStatus[item.status].text)+" ")])],1)]}},{key:"item.rappel",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.rappel),'dd/MM/yyyy à HH:mm'))+" ")])]}},{key:"item.formation",fn:function(ref){
var item = ref.item;
return [(item.formation)?_c('div',{staticClass:"d-flex flex-column text-truncate"},[_vm._v(" "+_vm._s(item.formation.nom.substring(0, 16))+" ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }